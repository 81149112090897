<template>
  <v-card style="text-align: center">
    <v-card-text>
      <v-btn
        outlined
        icon
        class="ma-2"
        :color="color"
        :disabled="!loaded"
        @click.native="seekTo(-3)"
      >
        <v-icon>mdi-rewind</v-icon>
      </v-btn>
      <v-btn
        outlined
        icon
        class="ma-2"
        :color="color"
        v-if="!playing"
        @click.native="play()"
        :disabled="!loaded"
      >
        <v-icon>mdi-play</v-icon>
      </v-btn>
      <v-btn
        outlined
        icon
        class="ma-2"
        :color="color"
        v-if="playing"
        @click.native="
          pause();
          $emit('pauseAudio');
        "
        :disabled="!loaded"
      >
        <v-icon>mdi-pause</v-icon>
      </v-btn>
      <v-btn
        outlined
        icon
        class="ma-2"
        :color="color"
        :disabled="!loaded"
        @click.native="seekTo(+3)"
      >
        <v-icon>mdi-fast-forward</v-icon>
      </v-btn>
      <v-btn
        outlined
        icon
        class="ma-2"
        :color="color"
        @click.native="stop()"
        :disabled="!loaded"
      >
        <v-icon>mdi-stop</v-icon>
      </v-btn>
      <v-btn
        outlined
        icon
        class="ma-2"
        :color="color"
        @click.native="mute()"
        :disabled="!loaded"
      >
        <v-icon v-if="!isMuted">mdi-volume-high</v-icon>
        <v-icon v-else>mdi-volume-mute</v-icon>
      </v-btn>
      <v-btn
        outlined
        icon
        class="ma-2"
        :color="color"
        @click.native="download()"
        :disabled="!loaded"
      >
        <v-icon>mdi-download</v-icon>
      </v-btn>
      <v-progress-linear
        v-model="percentage"
        height="15"
        style="margin-top: 15px; margin-bottom: 15px"
        @click.native="setPosition()"
        :disabled="!loaded"
      ></v-progress-linear>
      <p>{{ secondsToHms(currentTime) }} / {{ secondsToHms(duration) }}</p>
    </v-card-text>
  </v-card>
</template>
<script>
import { Howl } from "howler";
import EventBus from "@/event-bus";

export default {
  name: "AudioMarking",
  props: {
    color: {
      type: String,
      default: "#1BC5BD",
    },
    file: {
      type: String,
      default: null,
    },
    autoPlay: {
      type: Boolean,
      default: false,
    },
    format: {
      default: true,
    },
    loop: {
      default: false,
    },
    volume: {
      default: 1,
    },
  },
  computed: {},
  data() {
    return {
      firstPlay: true,
      isMuted: false,
      loaded: false,
      playing: false,
      paused: false,
      percentage: 0,
      currentTime: 0,
      duration: 0,
      whilePlaying: null,
      dataSrc: null,
      sound: null,
    };
  },
  watch: {},
  created() {},
  mounted() {
    this.dataSrc = this.file;
    this.init();
    EventBus.$on("pause-all", () => {
      this.pause();
    });
  },
  methods: {
    init: function () {
      this.sound = new Howl({
        src: this.dataSrc,
        loop: this.loop,
        volume: this.volume,
        autoplay: this.autoplay,
        format: this.format,
      });

      if (this.autoplay == true) {
        this.playing = true;
      }

      this.sound.on("load", () => {
        this.duration = this.sound.duration();
        this.loaded = true;
      });

      this.sound.on("play", () => {
        this.whilePlaying = setInterval(() => {
          this.currentTime = this.sound.seek();
          this.percentage = (this.sound.seek() / this.sound.duration()) * 100;
        }, 100);
      });

      this.sound.on("pause", () => {
        clearInterval(this.whilePlaying);
      });

      this.sound.on("end", () => {
        this.playing = false;
      });
    },
    setPlayTime(val) {
      if (this.playing == false) this.play();
      this.sound.seek(this.convertSecond(val));
    },
    setCurrentTime(val) {
      if (this.sound == null) return;
      this.sound.seek(this.convertSecond(val));
      this.currentTime = this.sound.seek();
      this.$emit("setInputTime", this.secondsToHms(this.currentTime));
      this.percentage =  this.convertSecond(val)*100/this.duration;
    },
    convertSecond(time) {
      let a = time.split(":");
      let seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
      return seconds;
    },
    secondsToHms(second) {
      let date = new Date(null);
      date.setSeconds(second); // specify value for SECONDS here
      let result = date.toISOString().substr(11, 8);
      return result;
    },
    setPosition() {
      if (this.sound == null) return;
      this.sound.seek(parseInt((this.duration / 100) * this.percentage));
      this.currentTime = this.sound.seek();
      this.$emit("setInputTime", this.secondsToHms(this.currentTime));
    },
    stop() {
      if (this.sound != null) {
        this.sound.stop();
        this.currentTime = 0;
        this.playing = false;
      }
    },
    play() {
      if (this.sound != null) {
        EventBus.$emit("pause-all");
        this.sound.play();
        this.playing = true;
        this.$emit("playAudio");
      }
    },
    pause() {
      if (this.sound != null) {
        this.sound.pause();
        this.playing = false;
      }
    },
    mute() {
      if (this.sound != null) {
        this.isMuted = !this.isMuted;
        this.sound.mute(this.isMuted);
      }
    },
    reload() {},
    seekTo(time) {
      if (this.sound !== null) {
        let currentTime = this.sound.seek();
        currentTime = currentTime + time;
        this.sound.seek(currentTime);
        this.currentTime = this.sound.seek();
        this.percentage = (currentTime / this.sound.duration()) * 100;
        this.$emit("setInputTime", this.secondsToHms(this.currentTime));
      }
    },
    getCurrentTimeAudio() {
      this.$emit(
        "setDataMarkingRecording",
        this.secondsToHms(this.currentTime)
      );
    },
    download() {
      this.sound.pause();
      window.open(this.file, "download");
    },
  },
  beforeDestroy() {
    this.pause();
  },
};
</script>
